import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Alert, Snackbar, TextField } from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import BackIcon from "../assets/icon_back.svg";
import { confirmPasswordResetRequest, resetPassword } from "../api/AccountApi";
import { ApiError } from "../api/ApiError";
import LoadingIndicator from "../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import AlertDialog from "../components/AlertDialog";
import logo from "../assets/adc_logo.svg";

export const ResetPasswordPage = () => {
  const navigate = useHistory();
  const { t } = useTranslation();

  const [isBusy, setIsBusy] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [resetPasswordToken, setResetPasswordToken] = useState<string | null>(
    null,
  );
  const [resetPasswordState, setResetPasswordState] = useState<string | null>(
    null,
  );

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    setFormValid(
      newPassword.length > 0 && confirmNewPassword.length > 0 && passwordsMatch,
    );
  }, [newPassword, confirmNewPassword, passwordsMatch]);

  const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => setNewPassword(e.target.value);

  const handleConfirmNewPasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => setConfirmNewPassword(e.target.value);

  const [isErrorSnackbarShown, setIsErrorSnackbarShown] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [isSuccessSnackbarShown, setIsSuccessSnackbarShown] = useState(false);

  const showError = (error: string) => {
    setErrorSnackbarMessage(error);
    setIsErrorSnackbarShown(true);
  };

  const handleErrorDismiss = () => {
    setIsErrorSnackbarShown(false);
  };

  const handleSuccessDismiss = () => {
    setIsSuccessSnackbarShown(false);
    navigate.replace("/");
  };

  const { data, isLoading, isError, isSuccess, error } = useQuery(
    "exchangeTokenForState",
    async () => {
      let resetPassToken: string | null = null;
      if (navigate.location.state) {
        const navigationState = navigate.location.state as {
          token?: string;
        };

        setResetPasswordToken(navigationState.token ?? null);
        resetPassToken = navigationState.token ?? null;
      }
      if (navigate.location.search) {
        const queryString = new URLSearchParams(navigate.location.search);
        setResetPasswordToken(queryString.get("token"));
        resetPassToken = queryString.get("token");
      }

      if (resetPassToken == null) {
        showError(t("dialog_error_generic"));
        throw new Error("No token provided");
      }

      const state = await confirmPasswordResetRequest(resetPassToken);
      setResetPasswordState(state);
      return state;
    },
    { retry: 3, staleTime: Infinity, refetchOnWindowFocus: false },
  );

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = async (
    e,
  ) => {
    try {
      setIsBusy(true);
      if (resetPasswordToken == null || resetPasswordState == null) {
        throw new Error("Reset password token or state missing.");
      }

      await resetPassword(resetPasswordToken, resetPasswordState, newPassword);

      setIsSuccessSnackbarShown(true);
    } catch (err) {
      console.error(`Failed to reset password, error: ${err}`);
      if (err && Object.hasOwn(err, "error")) {
        const error = err as ApiError;
        if (error.error === "password_not_strong_enough") {
          showError(t("dialog_alert_passwordTooWeak"));
        } else {
          const fullErrorsList = [error.error_description];
          if (error.error_detail) {
            fullErrorsList.push(error.error_detail);
          }
          showError(fullErrorsList.join(". "));
        }
      } else {
        showError(`${err}`);
      }
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <>
      <div className="form-background">
        <div className="form">
          <div className="back-button" onClick={() => navigate.replace("/")}>
            <img src={BackIcon} alt={t("button_back")} className="back-icon" />
            {t("button_back")}
          </div>
          {(isBusy || isLoading) && <LoadingIndicator />}
          {isError && <p>{t("dialog_error_confirmationLinkExpired")}</p>}
          {isSuccess && (
            <>
              <img src={logo} className="form-logo-only" alt="adc-logo" />
              <TextField
                variant="outlined"
                label={t("label_newPassword")}
                className="form-input"
                color={passwordsMatch ? "primary" : "error"}
                value={newPassword}
                onChange={handleNewPasswordChange}
                type="password"
                name="name"
                margin="normal"
              />
              <TextField
                variant="outlined"
                label={t("label_confirmNewPassword")}
                color={passwordsMatch ? "primary" : "error"}
                className="form-input"
                value={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                type="password"
                name="name"
                margin="normal"
              />
              {!passwordsMatch && (
                <p className="invalid-alert">
                  {t("validation_passwordsNoMatch")}
                </p>
              )}

              <Button
                className="form-button"
                type="submit"
                variant="contained"
                size="medium"
                startIcon={<PasswordIcon />}
                disabled={!formValid}
                onClick={handleSubmit}
              >
                {t("button_changePassword")}
              </Button>
              <Snackbar
                open={isErrorSnackbarShown}
                autoHideDuration={6000}
                onClose={handleErrorDismiss}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={handleErrorDismiss}
                  severity="error"
                >
                  {errorSnackbarMessage}
                </Alert>
              </Snackbar>
              <AlertDialog
                open={isSuccessSnackbarShown}
                onDismiss={handleSuccessDismiss}
                title={t("title_success")}
                content={<p>{t("dialog_alert_passwordUpdated")}</p>}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
