import { Paper, PaperProps } from "@mui/material";
import MagicFoilOn from "../assets/icon_magic_foil_on.svg";
import MagicFoilOff from "../assets/icon_magic_foil_off.svg";
import LightOn from "../assets/icon_light_on.svg";
import LightOff from "../assets/icon_light_off.svg";
import DoorOpenIcon from "../assets/icon_door_open.svg";
import DoorClosedIcon from "../assets/icon_door_closed.svg";
import MagnetLockLocked from "../assets/icon_lock_magnet_locked.svg";
import MagnetLockUnlocked from "../assets/icon_lock_magnet_unlocked.svg";
import MotorLockLocked from "../assets/icon_lock_motor_locked.svg";
import MotorLockUnlocked from "../assets/icon_lock_motor_unlocked.svg";
import { MouseEvent as ReactMouseEvent } from "react";
import { Loading } from "./Loading";
import { Device } from "../app/DeviceSlice";

type OnClickedCallback = (
  event: ReactMouseEvent<HTMLDivElement, MouseEvent>,
) => void;

export interface DoorCardProps extends PaperProps {
  device: Device;
  onMotorLockClicked?: OnClickedCallback;
  onMagnetLockClicked?: OnClickedCallback;
  onMagicFoilClicked?: OnClickedCallback;
  onDoorLightClicked?: OnClickedCallback;
  onHandleLightClicked?: OnClickedCallback;
  onCardClicked?: OnClickedCallback;
}

export const DoorCard: React.FC<DoorCardProps> = (args) => {
  const {
    device,
    onMotorLockClicked,
    onMagnetLockClicked,
    onMagicFoilClicked,
    onDoorLightClicked,
    onHandleLightClicked,
    onCardClicked,
    className,
    ...props
  } = args;

  const magnetLockIcon = device.magnetLockLocked
    ? MagnetLockLocked
    : MagnetLockUnlocked;
  const motorLockIcon = device.motorLockLocked
    ? MotorLockLocked
    : MotorLockUnlocked;
  const magnetBorderColor = device.magnetLockLocked ? "#7bbbcd" : "#ccc";
  const motorBorderColor = device.motorLockLocked ? "#7bbbcd" : "#ccc";

  // Filters generated by concatenating two filters:
  // 1. Full black filter (brightness(0) saturate(100))
  // 2. Final desired color from filter generated with https://isotropic.co/tool/hex-color-to-css-filter/
  const greenFilter =
    "brightness(0) saturate(100) invert(52%) sepia(100%) saturate(441%) hue-rotate(71deg) brightness(104%) contrast(140%)";
  const redFilter =
    "brightness(0) saturate(100) invert(14%) sepia(100%) saturate(6777%) hue-rotate(7deg) brightness(113%) contrast(121%)";
  const whiteFilter =
    "brightness(0) saturate(100) invert(100%) sepia(100%) saturate(1%) hue-rotate(253deg) brightness(102%) contrast(101%)";
  const hasFilter = device.lockMagneticSensorConnected ?? false;
  const iconFilter = device.lockMagneticSensorOpen ? greenFilter : redFilter;

  const finalClassName = [className, "card", "door-card"].join(" ");

  return (
    <Paper
      {...props}
      variant="outlined"
      square={false}
      className={finalClassName}
      onClick={onCardClicked}
    >
      <div className="card-content">
        {device.status !== "ready" && device.status !== "offline" && (
          <Loading className="card-busy" />
        )}

        <div className="card-icon">
          <DoorIcon
            className="device-icon"
            devicetype={device.devicetype}
            magneticSensorOpen={device.magneticSensorOpen!}
          />
          {device.connected !== true && <p className="offline-icon">!</p>}
        </div>

        <div className="card-name">{device.name}</div>

        <div className="card-control">
          {device.magnetLockConnected && (
            <div
              className="image-action-button"
              style={{
                border: `1px solid ${magnetBorderColor}`,
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onMagnetLockClicked) {
                  onMagnetLockClicked(event);
                }
              }}
            >
              <img
                src={magnetLockIcon}
                style={{
                  filter: hasFilter ? iconFilter : whiteFilter,
                }}
                alt="Magnet lock icon"
              />
            </div>
          )}
          {device.motorLockConnected && (
            <div
              className="image-action-button"
              style={{
                border: `1px solid ${motorBorderColor}`,
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onMotorLockClicked) {
                  onMotorLockClicked(event);
                }
              }}
            >
              <img
                src={motorLockIcon}
                style={{
                  filter: hasFilter ? iconFilter : whiteFilter,
                }}
                alt="Motor lock icon"
              />
            </div>
          )}
          {device.magicFoilConnected && (
            <div
              className="image-action-button"
              style={{
                border: `1px solid ${
                  device.magicFoilOpacity === 0 ? "#7bbbcd" : "#ccc"
                }`,
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onMagicFoilClicked) {
                  onMagicFoilClicked(event);
                }
              }}
            >
              <img
                src={device.magicFoilOpacity === 1 ? MagicFoilOff : MagicFoilOn}
                alt={
                  device.magicFoilOpacity === 1
                    ? "Light off icon"
                    : "Light on icon"
                }
              />
            </div>
          )}
          {device.doorLightConnected && (
            <div
              className="image-action-button"
              style={{
                border: `1px solid ${
                  device.doorLightBrightness === 1 ? "#7bbbcd" : "#ccc"
                }`,
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onDoorLightClicked) {
                  onDoorLightClicked(event);
                }
              }}
            >
              <img
                src={device.doorLightBrightness === 0 ? LightOff : LightOn}
                alt={
                  device.doorLightBrightness === 0
                    ? "Light off icon"
                    : "Light on icon"
                }
              />
            </div>
          )}
          {device.handleLightConnected && (
            <div
              className="image-action-button"
              style={{
                border: `1px solid ${
                  device.handleLightBrightness === 1 ? "#7bbbcd" : "#ccc"
                }`,
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onHandleLightClicked) {
                  onHandleLightClicked(event);
                }
              }}
            >
              <img
                src={device.handleLightBrightness === 0 ? LightOff : LightOn}
                alt={
                  device.handleLightBrightness === 0
                    ? "Light off icon"
                    : "Light on icon"
                }
              />
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
};

interface DoorIconProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  devicetype: string;
  magneticSensorOpen: boolean;
}

const DoorIcon: React.FC<DoorIconProps> = ({
  devicetype,
  magneticSensorOpen,
  ...props
}) => {
  let iconImage;

  switch (devicetype.toLowerCase()) {
    case "home_door":
      iconImage = magneticSensorOpen ? DoorOpenIcon : DoorClosedIcon;
      break;
    default:
      iconImage = DoorClosedIcon;
  }

  return <img {...props} src={iconImage} alt="Doors icon" />;
};
