import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { AppleAuthResponse } from "react-apple-signin-auth";
import "./SignInWithApple.css";

const { appleClientId, appleRedirectURI } = (
  window as Window &
    typeof globalThis & {
      config: { appleClientId?: string; appleRedirectURI?: string };
    }
).config;

type AppleSignInButtonProps = {
  onSuccess: (response: AppleAuthResponse) => void;
  onError: (error: any) => void;
};

const AppleSignInButton: React.FC<AppleSignInButtonProps> = ({
  onSuccess,
  onError,
}) => {
  return (
    <AppleSignin
      authOptions={{
        clientId: appleClientId!,
        scope: "email name",
        redirectURI: appleRedirectURI!,
        usePopup: true,
      }}
      className="sign-in-with-apple signin-button"
      uiType="light"
      onSuccess={(response: AppleAuthResponse) => onSuccess(response)}
      onError={(error: any) => onError(error)}
      skipScript={false}
      buttonExtraChildren="Sign in with Apple"
    />
  );
};

export default AppleSignInButton;
