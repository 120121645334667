import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import {
  abortAction,
  CompleteDeviceInfo,
  deleteDevice,
  DeviceAction,
  DeviceSpecifier,
  getDeviceInfo,
  getDeviceList,
  getFullDeviceList,
  performAction,
} from "../api/DeviceApi";
import { websocketDeviceStateUpdated } from "./adcWebsocketMiddleware";
import { DeviceLink, getDeviceLinkList } from "../api/DeviceLinkApi";

export type Device = CompleteDeviceInfo & {
  deviceLinkSpecifier?: DeviceSpecifier;
  totalStateUpdates?: number;
  totalRestarts?: number;
};

export interface DeviceSliceData {
  devices: Record<string, Device>;
  deviceLinks: Record<string, DeviceLink>;
  isRefreshingDevices: boolean;
  initialDeviceRefreshDone: boolean;
}

export const forceDeleteAllDevices = createAsyncThunk(
  "device/deleteAll",
  async (_, thunkAPI) => {
    try {
      let deviceList = await getDeviceList();

      for (let deviceId in deviceList) {
        try {
          await deleteDevice(deviceId, true);
        } catch (err) {
          console.warn(`Failed to delete device: ${err}`);
        }
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const refreshDeviceLinkDevices = createAsyncThunk(
  "device/refreshAllDeviceLink",
  async (args: { deviceLinkToken: string }, thunkAPI) => {
    try {
      const deviceLinks = await getDeviceLinkList(args.deviceLinkToken);
      const availableDevices: Device[] = await getFullDeviceList(
        args.deviceLinkToken,
      );
      const relevantDeviceLink = deviceLinks.find(
        (link) => link.token === args.deviceLinkToken,
      );
      if (relevantDeviceLink) {
        for (let index in relevantDeviceLink.devices) {
          const relevantDevice = availableDevices.find(
            (device) => device.id === relevantDeviceLink.devices[index],
          );
          if (relevantDevice) {
            relevantDevice.deviceLinkSpecifier =
              relevantDeviceLink.deviceSpecifiers?.[index];
          }
        }
      } else {
        console.warn(
          "The specified device link could not be found, device specifiers may be wrong.",
        );
        console.warn("Available links:", deviceLinks);
      }
      return { deviceLink: relevantDeviceLink, devices: availableDevices };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const refreshDeviceLinkDevice = createAsyncThunk(
  "device/refreshDeviceLink",
  async (
    { deviceLinkToken, id }: { deviceLinkToken: string; id: string },
    thunkAPI,
  ) => {
    try {
      return { ...(await getDeviceInfo(id, deviceLinkToken)), id };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const refreshDevices = createAsyncThunk(
  "device/refreshAll",
  async (_, thunkAPI) => {
    try {
      return await getFullDeviceList();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const refreshDevice = createAsyncThunk(
  "device/refresh",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      return { ...(await getDeviceInfo(id)), id };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

type ExecuteDeviceActionParams = {
  deviceIds: string[];
  action: DeviceAction;
  deviceLinkToken?: string;
};

/**
 * @description Executes an action on a set of devices.
 * @returns An array of ActionResults
 */
export const executeDeviceAction = createAsyncThunk(
  "device/action/execute",
  async (
    { deviceIds, action, deviceLinkToken }: ExecuteDeviceActionParams,
    thunkAPI,
  ) => {
    try {
      return await performAction(deviceIds, action, false, deviceLinkToken);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const abortCurrentDeviceAction = createAsyncThunk(
  "device/action/abort",
  async (
    {
      deviceIds,
      deviceLinkToken,
    }: { deviceIds: string[]; deviceLinkToken: string },
    thunkAPI,
  ) => {
    return await abortAction(deviceIds, deviceLinkToken);
  },
);

export const DeviceSlice = createSlice<
  DeviceSliceData,
  SliceCaseReducers<DeviceSliceData>,
  string,
  SliceSelectors<DeviceSliceData>,
  string
>({
  name: "device",
  initialState: {
    devices: {},
    deviceLinks: {},
    isRefreshingDevices: false,
    initialDeviceRefreshDone: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forceDeleteAllDevices.fulfilled, (state, action) => {
      state.devices = {};
    });
    builder.addCase(refreshDevices.pending, (state, action) => {
      state.isRefreshingDevices = true;
    });
    builder.addCase(refreshDevices.fulfilled, (state, action) => {
      state.devices = Object.fromEntries(
        action.payload.map((val) => [val.id, val]),
      );
      state.isRefreshingDevices = false;
      state.initialDeviceRefreshDone = true;
    });
    builder.addCase(refreshDevice.fulfilled, (state, action) => {
      const deviceInfo = action.payload;
      state.devices[deviceInfo.id] = {
        ...state.devices[deviceInfo.id],
        ...deviceInfo,
      };
    });
    builder.addCase(refreshDeviceLinkDevices.pending, (state, action) => {
      state.isRefreshingDevices = true;
    });
    builder.addCase(refreshDeviceLinkDevices.fulfilled, (state, action) => {
      if (action.payload.deviceLink !== undefined) {
        state.deviceLinks = {
          ...state.deviceLinks,
          ...Object.fromEntries([
            [action.payload.deviceLink.token, action.payload.deviceLink],
          ]),
        };
      }
      state.devices = {
        ...state.devices,
        ...Object.fromEntries(
          action.payload.devices.map((val) => [val.id, val]),
        ),
      };
      state.isRefreshingDevices = false;
      state.initialDeviceRefreshDone = true;
    });
    builder.addCase(refreshDeviceLinkDevice.fulfilled, (state, action) => {
      const deviceInfo = action.payload;
      state.devices[deviceInfo.id] = {
        ...state.devices[deviceInfo.id],
        ...deviceInfo,
      };
    });
    builder.addCase(executeDeviceAction.fulfilled, (state, action) => {
      for (const actionStatus of action.payload) {
        if (actionStatus.status.toLowerCase() !== "success") {
          continue;
        }

        for (const deviceId of actionStatus.device_ids) {
          if (!state.devices[deviceId]) {
            continue;
          }

          if (actionStatus.position) {
            state.devices[deviceId].position = actionStatus.position;
          }
          if (actionStatus.inclination) {
            state.devices[deviceId].inclination = actionStatus.inclination;
          }
        }
      }
    });
    builder.addCase(websocketDeviceStateUpdated, (state, action) => {
      if (state.devices[action.payload.deviceId] !== undefined) {
        const device = state.devices[action.payload.deviceId];
        if (
          device.totalRestarts === undefined ||
          device.totalStateUpdates === undefined ||
          device.totalRestarts < action.payload.state.totalRestarts ||
          (device.totalRestarts === action.payload.state.totalRestarts &&
            device.totalStateUpdates <= action.payload.state.totalStateUpdates)
        ) {
          state.devices[action.payload.deviceId] = {
            ...state.devices[action.payload.deviceId],
            ...action.payload.state,
          };
        }
      }
    });
  },
});
