import {
  Middleware,
  ThunkDispatch,
  UnknownAction,
  createAction,
  isAction,
} from "@reduxjs/toolkit";
import {
  WebsocketAuth,
  currentUserWebsocketMapKey,
  logoutAsync,
  refreshWebsocketToken,
  websocketTokenAccepted,
  websocketTokenExpired,
  websocketTokenRejected,
} from "./AccountSlice";

import { RootState } from "./store";
import {
  AdcWebsocketMessageSubject,
  AuthLoginMessage,
  DeviceStateUpdateMessage,
  isAuthAcceptedMessage,
  isAuthRejectedMessage,
  isDeviceStateUpdateMessage,
} from "./AdcWebsocketMessages";

export interface AdcWebsocketOptions {
  authToken: string;
}

export const startAdcWebsocket = createAction<AdcWebsocketOptions>(
  "device/tracking/start",
);

export const websocketDeviceStateUpdated = createAction<{
  deviceId: string;
  state: DeviceStateUpdateMessage["data"]["state"];
}>("device/tracking/state_update");

export const websocketConnectionError = createAction<{ forAuth: string }>(
  "device/tracking/connectionError",
);

const websocketUri =
  (
    window as Window &
      typeof globalThis & { config: { websocketSecureUri: string } }
  ).config.websocketSecureUri ?? "home-stage.adcsistemi.si/websocket-notifier";

export const adcWebsocketMiddleware: () => Middleware<
  {},
  RootState,
  ThunkDispatch<RootState, unknown, UnknownAction>
> = () => {
  let websocketMap: Record<string, WebSocket> = {};
  let websocketTokenExpiresTimer: ReturnType<typeof setTimeout> | undefined =
    undefined;

  return (storeApi) => (next) => (action) => {
    const { dispatch, getState } = storeApi;

    console.log("adcWebsocketMiddleware", action);

    const createLoginMessage = (websocketToken: string): AuthLoginMessage => {
      return {
        subject: AdcWebsocketMessageSubject.AUTH_LOGIN,
        data: { token: websocketToken },
      };
    };

    const createWebsocket = ({
      forAuth,
      websocketAuth,
    }: {
      forAuth: string;
      websocketAuth: WebsocketAuth;
    }) => {
      const websocket = new WebSocket(`wss://${websocketUri}`);

      websocket.addEventListener("open", (ev) => {
        console.log(`Websocket (${forAuth}) connected:`, ev);
        websocket.send(JSON.stringify(createLoginMessage(websocketAuth.token)));
      });
      websocket.addEventListener("close", (ev) => {
        console.log(`Websocket (${forAuth}) closed:`, ev);
      });
      websocket.addEventListener("error", (ev) => {
        console.log(`Websocket (${forAuth}) error:`, ev);
        dispatch(websocketConnectionError({ forAuth }));
      });
      websocket.addEventListener("message", (ev) => {
        console.log(`Websocket (${forAuth}) message:`, ev);
        const messageData: unknown = JSON.parse(ev.data);
        handleWebsocketMessage(forAuth, messageData);
      });

      return websocket;
    };

    const handleWebsocketMessage = (forAuth: string, messageData: any) => {
      if (isDeviceStateUpdateMessage(messageData)) {
        dispatch(websocketDeviceStateUpdated(messageData.data));
      } else if (isAuthAcceptedMessage(messageData)) {
        dispatch(
          websocketTokenAccepted({
            forAuth: forAuth,
            expiresAt: messageData.data.expiresAt,
          }),
        );
        if (websocketTokenExpiresTimer) {
          clearTimeout(websocketTokenExpiresTimer);
          websocketTokenExpiresTimer = undefined;
        }
        websocketTokenExpiresTimer = setTimeout(() => {
          dispatch(websocketTokenExpired({ forAuth: forAuth }));
        });
      } else if (isAuthRejectedMessage(messageData)) {
        dispatch(
          websocketTokenRejected({
            forAuth: forAuth,
          }),
        );
        dispatch(
          refreshWebsocketToken({
            deviceLinkToken:
              forAuth !== currentUserWebsocketMapKey ? forAuth : undefined,
          }),
        );
      }
    };

    if (isAction(action)) {
      if (refreshWebsocketToken.fulfilled.match(action)) {
        const { websocketAuth, forAuth } = action.payload;

        if (websocketMap[forAuth] !== undefined) {
          const websocket = websocketMap[forAuth];
          if (websocket.readyState === WebSocket.OPEN) {
            // Just refresh the auth token on the existing connection
            websocket.send(
              JSON.stringify(createLoginMessage(websocketAuth.token)),
            );
            return;
          }

          websocket.close();
          delete websocketMap[forAuth];
        }

        websocketMap[forAuth] = createWebsocket({ forAuth, websocketAuth });
      } else if (websocketConnectionError.match(action)) {
        // TODO: Check if the websocket maybe needs to be closed after error
        delete websocketMap[action.payload.forAuth];
        const state = getState();
        const websocketAuth =
          state.account.websocketAuthMap[action.payload.forAuth];
        if (websocketAuth !== undefined) {
          websocketMap[action.payload.forAuth] = createWebsocket({
            forAuth: action.payload.forAuth,
            websocketAuth,
          });
        } else {
          console.warn(
            `WebSocket connection errored out but there is not websocket auth for this websocket (${action.payload.forAuth}) in the state`,
          );
        }
      } else if (logoutAsync.fulfilled.match(action)) {
        for (const [forAuth, websocket] of Object.entries(websocketMap)) {
          websocket.close();
          delete websocketMap[forAuth];
        }
      }
    }

    return next(action);
  };
};
